import React from 'react';
import ReactDOM from 'react-dom';
import './styles/styles.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from './store';
import {BrowserRouter} from 'react-router-dom';
import { AccesibilidadProvider } from './contexts/AccesibilidadContext';
import ReactGA from 'react-ga4';

ReactGA.initialize('UA-109938527-2');

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AccesibilidadProvider>
        <Provider>
          <App />
        </Provider>
      </AccesibilidadProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);
